import React from 'react';
import { Container, Row } from 'react-bootstrap';

const Home = () => {
 return (
    <Container fluid style={{ padding: '60px 0', backgroundColor: '#282c34' }}>
 <Row>
    {/* Your component content goes here */}
 </Row>
</Container>
 );
};

export default Home;